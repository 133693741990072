import React, { ReactNode, useState } from 'react';
import styled from '@emotion/styled';
import FaqItem from './FaqItem';
import { Section } from '@/components/elements';

interface ComponentProps {
  title?: string;
  description?: string | ReactNode;
  items: any[];
  bgColor?: string;
  fullwidth?: boolean;
  lang?: string;
}

const Header = styled.div`
  margin-bottom: 2.5rem;
`;

const FaqItems = styled.div`
  border-top: 1px solid ${(props) => props.theme.palette.gray.light};
`;

const Faq: React.FC<ComponentProps> = ({
  title,
  description,
  items,
  bgColor,
  fullwidth,
  lang,
}) => {
  const [activeFaq, setActiveFaq] = useState<number>(1000);

  const handleShowFaq = (e, value) => {
    if (activeFaq !== value) {
      setActiveFaq(value);
    } else {
      setActiveFaq(1000);
    }
  };

  return (
    <Section bgColor={bgColor} fullwidth={fullwidth}>
      <Header>
        {title && <h2>{title}</h2>}
        {description && <p>{description}</p>}
      </Header>
      <FaqItems>
        {items.map((item, index) => {
          const question =
            typeof item.question === `string`
              ? item.question
              : item.question[lang];

          const answer =
            typeof item.answer === `string` ? item.answer : item.answer[lang];

          return (
            <FaqItem
              key={index}
              itemId={index}
              question={question}
              answer={answer}
              show={index === activeFaq}
              onClick={handleShowFaq}
            />
          );
        })}
      </FaqItems>
    </Section>
  );
};
Faq.defaultProps = {
  fullwidth: false,
  title: null,
  description: null,
  bgColor: null,
  lang: `en`,
};

export default Faq;
