const planItems = [
  {
    id: `basic`,
    color: `#1abc9c`,
    popular: false,
    title: `Basic`,
    description: `Perfect for getting started.`,
    price: {
      monthly: `1,088`,
      yearly: `989`,
    },
    features: [
      `Single Website`,
      `3 Email Addresses`,
      `5GB Storage`,
      `CPanel Control`,
      `FREE SSL Certificate`,
    ],
    url: `/`,
    show: true,
  },
  {
    id: `plus`,
    color: `#3498db`,
    popular: true,
    title: `Plus +`,
    description: `Best for standard websites.`,
    price: {
      monthly: `1,979`,
      yearly: `1,799`,
    },
    features: [
      `Single Website`,
      `5 Email Addresses`,
      `15GB Storage`,
      `CPanel Control`,
      `FREE SSL Certificate`,
    ],
    url: `/`,
    show: true,
  },
  {
    id: `pro`,
    color: `#9b59b6`,
    popular: false,
    title: `Pro`,
    description: `Ideal for growing businesses.`,
    price: {
      monthly: `3,365`,
      yearly: `3,059`,
    },
    features: [
      `Multiple Websites`,
      `15 Email Addresses`,
      `30GB Storage`,
      `CPanel Control`,
      `FREE SSL Certificate`,
    ],
    url: `/`,
    show: true,
  },
  {
    id: `enterprise`,
    color: `#f1c40f`,
    popular: false,
    title: `Enterprise`,
    description: `Home`,
    price: {
      monthly: `3,000`,
      yearly: `3,000`,
    },
    features: [`Feature 1`, `Feature 1`, `Feature 1`],
    url: `/`,
    show: false,
  },
];

export const planItemsItems = planItems;

export default planItems;
