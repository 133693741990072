import React from 'react';
import styled from '@emotion/styled';
import Linkify from 'react-linkify';

interface ComponentProps {
  itemId: number | string;
  show: boolean;
  question: string;
  answer: string;
  onClick: (
    event: React.MouseEvent<HTMLDivElement>,
    itemId: number | string,
  ) => void;
}

interface ShowProps {
  show: boolean;
}

const Container = styled.div`
  padding: 30px 0;
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.light};
  text-align: left;
`;
const QuestionWrapper = styled.div<ShowProps>`
  position: relative;
  font-weight: bold;
  padding-left: 30px;
  font-size: 1.3rem;
  cursor: pointer;
  transition: 0.5s all;
  color: ${(props) =>
    props.show ? props.theme.palette.primary.main : props.theme.text.default};
  &:hover {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;
const QuestionIcon = styled.span<ShowProps>`
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  left: 0;
  top: 45%;
  transform: translateY(-50%);

  &:before , &:after{
    content: "";
    position: absolute;
    height: 4px;
    width: 14px;
    background-color: ${(props) => props.theme.palette.primary.main};
    opacity: 0.75;
    top:45%;
    left:50%;
    transition: 1s ease;
    transform: ${(props) =>
      props.show
        ? `translate(-50%, -50%) rotate(180deg)`
        : `translate(-50%, -50%)`};
  }
  &:after{
    transform: ${(props) =>
      props.show
        ? `translate(-50%, -50%) rotate(180deg)`
        : `translate(-50%, -50%) rotate(90deg)`});
  }
`;
const AnswerWrapper = styled.div<ShowProps>`
  max-height: ${(props) => (props.show ? `350px` : 0)};
  overflow: hidden;
  padding-left: 30px;
  opacity: ${(props) => (props.show ? 1 : 0)};
  color: ${(props) => props.theme.palette.primary.text};
  transition: all 0.8s ease-in-out;
`;
const AnswerLink = styled.a`
  color: ${(props) => props.theme.palette.primary.light};
  &:active {
    color: ${(props) => props.theme.palette.primary.light};
  }
  &:visited {
    color: ${(props) => props.theme.palette.primary.light};
  }
  &:hover {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

const FaqItem: React.FC<ComponentProps> = ({
  itemId,
  question,
  answer,
  show,
  onClick,
}) => (
  <Container onClick={(e) => onClick(e, itemId)}>
    <QuestionWrapper show={show}>
      <QuestionIcon show={show} />
      {question}
    </QuestionWrapper>
    <AnswerWrapper show={show}>
      <Linkify
        componentDecorator={(decoratedHref, decoratedText, index) => (
          <AnswerLink target="blank" href={decoratedHref} key={index}>
            {decoratedText}
          </AnswerLink>
        )}
      >
        <p style={{ marginBottom: 0 }}>
          {answer.split(`\n`).map((text, idx) => (
            <span key={idx}>
              {text}
              <br />
            </span>
          ))}
        </p>
      </Linkify>
    </AnswerWrapper>
  </Container>
);

export default FaqItem;
