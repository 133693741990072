const hostingFaqData = [
  {
    sectionId: `general`,
    question: {
      en: `How do I get started with MyServerJapan?`,
      ja: `Question 1 - ja`,
    },
    answer: {
      en: `It's simple to get started with us! Simply select a hosting plan that best fits your needs and begin the process. \n  It's that easy!`,
      ja: `Answer 1 - ja`,
    },
  },
  {
    sectionId: `general`,
    question: {
      en: `What's a domain name and do I need one?`,
      ja: `Question 2 - ja`,
    },
    answer: {
      en: `A domain name is the address that shows where your website is. It allows your visitors to find you online. An example would be "www.myserverjapan.com".  We can register one for you, transfer your current one or you can just point it to our servers.`,
      ja: `Answer 2 - ja`,
    },
  },
  {
    sectionId: `general`,
    question: {
      en: `What's Shared Hosting?`,
      ja: `Question 2 - ja`,
    },
    answer: {
      en: `Shared Server Hosting is the most common and affordable form of website hosting. Multiple users share the resources of a secured server or group of secured servers to host their websites online for others to view.`,
      ja: `Answer 2 - ja`,
    },
  },
  {
    sectionId: `general`,
    question: {
      en: `What about Email?`,
      ja: `Question 2 - ja`,
    },
    answer: {
      en: `Having a website is the first step, but having an Email address is just as equally important. All of our hosting plans provide at least one email address of your choice!`,
      ja: `Answer 2 - ja`,
    },
  },
  {
    sectionId: `general`,
    question: {
      en: `Can you help me build my website?`,
      ja: `Can you help me build my website?`,
    },
    answer: {
      en: `Our dedicated team of website designers and programmers at our mother company www.redcranesolutions.com can provide services to get you online. Please get in contact to find out more!`,
      ja: `Answer 2 - ja`,
    },
  },
  {
    sectionId: `general`,
    question: {
      en: `Do you provide English support?`,
      ja: `Do you provide English support?`,
    },
    answer: {
      en: `Yes, we do provide English support. In fact our company works fully in English, so all our systems are based on English and Japanese as standard.`,
      ja: `Answer 2 - ja`,
    },
  },
  {
    sectionId: `general`,
    question: {
      en: `Can I use wordpress or other frameworks?`,
      ja: `Can I use wordpress?`,
    },
    answer: {
      en: `We full support wordpress and many other frameworks on our servers. Our support team will be there to guide you step by step in case you have a problem.`,
      ja: `Answer 2 - ja`,
    },
  },
  {
    sectionId: `general`,
    question: {
      en: `Do you accept credit cards, paypal, etc?`,
      ja: `Do you accept credit cards, paypal, etc?`,
    },
    answer: {
      en: `We do accept credit cards and paypal via the secure processing of Stripe.`,
      ja: `Answer 2 - ja`,
    },
  },
];

export { hostingFaqData };
