import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

interface ComponentProps {
  popular?: boolean;
  popularLabel: string;
  currency: string;
  color?: string;
  title: string;
  description: string;
  price: {
    monthly: string;
    yearly: string;
  };
  prefix: {
    monthly: string;
    yearly: string;
  };
  suffix: {
    monthly: string;
    yearly: string;
  };
  features: string[];
  url: string;
  btnLabel: string;
}

interface CardProps {
  popular?: boolean;
  color: string;
}

const Card = styled.div<CardProps>`
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 7px;
  box-shadow: 1px 1px 10px 1px #ddd;
  max-width: 300px;
  position: relative;
  width: 100%;
  text-align: center;
  margin: 0.8rem;
  margin-top: ${(props) => (props.popular ? 0 : `1rem`)};
  margin-bottom: 1rem;
  padding: 1rem 0;
  border-top: ${(props) => `6px solid ${props.color}`};
`;

const CardMostPopular = styled.span<CardProps>`
  font-size: 15px;
  font-weight: 700;
  background-color: ${(props) => props.color};
  color: #fff;
  padding: 0.25em 1rem;
  border-radius: 15px;
  text-transform: uppercase;
`;

const CardNoMostPopular = styled.div`
  //height: 1rem;
`;

const CardTop = styled.div`
  h3 {
    margin-bottom: 0;
  }

  p {
    margin-top: 0.3rem;
    font-size: 1rem;
    color: ${(props) => props.theme.palette.gray.dark};
  }
`;
const CardBilling = styled.div`
  padding: 0.8rem;
  background-color: #f3f4f5;

  p {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
    font-size: 0.9rem;
    color: ${(props) => props.theme.palette.gray.dark};
  }

  .price {
    font-size: 32px;
    font-weight: 700;
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

const CardFeatures = styled.div`
  min-height: 150px;
  color: ${(props) => props.theme.palette.gray.dark};
  ul li {
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
  }
`;
const CardAction = styled.div`
  padding-bottom: 2rem;
`;

const CardActionButton = styled(Link)`
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 0.8rem 1rem;
  border-radius: 0.6rem;
  color: ${(props) => props.theme.palette.primary.contrastText};
  &:hover {
    background-color: ${(props) => props.theme.palette.primary.light};
  }
`;

const PricePlanCard: React.FC<ComponentProps> = ({
  popular,
  popularLabel,
  currency,
  color,
  title,
  description,
  price,
  features,
  url,
  prefix,
  suffix,
  btnLabel,
}) => (
  <Card popular={popular} color={color}>
    {popular ? (
      <CardMostPopular color={color}>{popularLabel}</CardMostPopular>
    ) : (
      <CardNoMostPopular />
    )}
    <CardTop>
      <h3>{title}</h3>
      <p>{description}</p>
    </CardTop>
    <CardBilling>
      <p>
        {prefix.yearly}
        <strong className="price">
          {currency}
          {price.yearly}
        </strong>
        <strong> {suffix.yearly}</strong>
      </p>
      <p>
        <span className="recurrency">
          {prefix.monthly} {currency + price.monthly} {suffix.monthly}
        </span>
      </p>
    </CardBilling>
    <CardFeatures>
      <ul>
        {features.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </CardFeatures>
    <CardAction>
      <CardActionButton to={url}>{btnLabel}</CardActionButton>
    </CardAction>
  </Card>
);

PricePlanCard.defaultProps = {
  popular: false,
  color: `#cccccc`,
};

export default PricePlanCard;
