import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import PricePlanCard from '@/components/PricePlan/PricePlanCard';
import { Section } from '@/components/elements';

interface ComponentProps {
  title?: string;
  description?: string | ReactNode;
  footerText?: string | ReactNode;
  items: any[];
  bgColor?: string;
  fullwidth?: boolean;
  lang?: string;
  currency?: string;
  prefix?: {
    monthly: string;
    yearly: string;
  };
  suffix?: {
    monthly: string;
    yearly: string;
  };
  btnLabel?: string;
  popularLabel?: string;
}

const PlanItems = styled.div`
  margin-top: 2.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
`;

const Footer = styled.div`
  margin-top: 1rem;
  align-items: center;
  font-size: 0.8rem;
  color: ${(props) => props.theme.palette.gray.main};
`;

const PricePlan: React.FC<ComponentProps> = ({
  title,
  description,
  items,
  bgColor,
  fullwidth,
  lang,
  currency,
  prefix,
  suffix,
  btnLabel,
  popularLabel,
  footerText,
}) => (
  <Section bgColor={bgColor} fullwidth={fullwidth}>
    {title && <h2>{title}</h2>}
    {description && <p>{description}</p>}

    <PlanItems>
      {items.map((item, index) => {
        if (item.show) {
          const itemUrl =
            typeof item.url === `string` ? item.url : item.url[lang];
          const itemTitle =
            typeof item.title === `string` ? item.title : item.title[lang];
          let itemBtnLabel = null;
          if (item.btnLabel) {
            itemBtnLabel =
              typeof item.btnLabel === `string`
                ? item.btnLabel
                : item.btnLabel[lang];
          }
          let itemPopularLabel = null;
          if (item.popularLabel) {
            itemPopularLabel =
              typeof item.popularLabel === `string`
                ? item.popularLabel
                : item.popularLabel[lang];
          }
          const itemDescription =
            typeof item.description === `string`
              ? item.description
              : item.description[lang];

          const itemFeatures = Array.isArray(item.features)
            ? item.features
            : item.features[lang];

          return (
            <PricePlanCard
              key={index}
              popular={item.popular}
              popularLabel={itemPopularLabel || popularLabel}
              color={item.color}
              title={itemTitle}
              description={itemDescription}
              price={item.price}
              url={itemUrl}
              features={itemFeatures}
              currency={currency}
              prefix={prefix}
              suffix={suffix}
              btnLabel={itemBtnLabel || btnLabel}
            />
          );
        }
        return null;
      })}
    </PlanItems>
    {footerText && <Footer>{footerText}</Footer>}
  </Section>
);

PricePlan.defaultProps = {
  title: null,
  description: null,
  footerText: null,
  bgColor: `default`,
  fullwidth: false,
  lang: `en`,
  currency: `¥`,
  prefix: {
    monthly: `Billed Annually or `,
    yearly: ``,
  },
  suffix: {
    monthly: `/ monthly`,
    yearly: `/ mo.`,
  },
  btnLabel: `More...`,
  popularLabel: `Most Popular`,
};

export default PricePlan;
